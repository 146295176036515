import React from 'react'
import { graphql } from 'gatsby'
import { gql } from '@faststore/graphql-utils'
import { NpmHomePage } from '@plurix/ecom-pages'

import { CustomTheme } from '../components/ui/CustomTheme'
import { useDynamicShowcases } from '../hooks/useDynamicShowcases'

import type { PageProps } from 'gatsby'
import type { HomePageQueryQuery } from '@generated/graphql'

export type HomePageProps = PageProps<HomePageQueryQuery>

const Page = ({ data, location }: HomePageProps) => {
  const { loading, dynamicShowcases } = useDynamicShowcases()

  return (
    <>
      <NpmHomePage
        data={data}
        location={location}
        loading={loading}
        dynamicShowcases={dynamicShowcases}
      />

      <CustomTheme />
    </>
  )
}

export const searchSuggestionsQuery = gql`
  query SearchSuggestionsQuery(
    $limit: Int!
    $term: String!
    $selectedFacets: [IStoreSelectedFacet!]
  ) {
    search(first: $limit, term: $term, selectedFacets: $selectedFacets) {
      suggestions {
        terms {
          value
        }
        products {
          ...ProductSummary_product
        }
      }
    }
  }
`

export const query = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        title
        description
        titleTemplate
      }
    }
  }
`

export const fragment = graphql`
  fragment AdditionalProductInfos on StoreProduct {
    clusterHighlights {
      id
      name
    }
    unitMultiplier
    measurementUnit
    sellers {
      sellerName
    }
    categoryTree
    priceWithoutPromotions
    hasClubPrice
    customOffers {
      listPriceCustom
      spotPriceKg
      spotPriceCustom
      hasDiscount
    }
    skuSpecifications {
      field {
        originalName
      }
      values {
        originalName
      }
    }
  }

  fragment ProductDetailsFragment_product on StoreProduct {
    ...AdditionalProductInfos
    categoryTree
    priceWithoutPromotions
    id: productID
    sku
    name
    gtin
    description

    isVariantOf {
      productGroupID
      name
      skuVariants {
        activeVariations
        slugsMap(dominantVariantName: "Color")
        availableVariations(dominantVariantName: "Color")
      }
    }

    image {
      url
      alternateName
    }

    brand {
      name
    }

    offers {
      lowPrice
      offers {
        availability
        price
        listPrice
        seller {
          identifier
        }
      }
    }

    breadcrumbList {
      itemListElement {
        item
        name
        position
      }
    }

    # Contains necessary info to add this item to cart
    ...CartProductItem
  }

  fragment ProductSummary_product on StoreProduct {
    ...AdditionalProductInfos
    id: productID
    slug
    sku
    brand {
      brandName: name
    }
    name
    gtin

    isVariantOf {
      productGroupID
      name
    }

    image {
      url
      alternateName
    }

    brand {
      name
    }

    offers {
      lowPrice
      offers {
        availability
        price
        listPrice
        quantity
        seller {
          identifier
        }
      }
    }
  }

  fragment CartItem on StoreOffer {
    seller {
      identifier
    }
    quantity
    price
    listPrice
    itemOffered {
      ...CartProductItem
    }
  }

  fragment CartMessage on StoreCartMessage {
    text
    status
  }

  fragment CartProductItem on StoreProduct {
    ...AdditionalProductInfos
    sku
    name
    image {
      url
      alternateName
    }
    brand {
      name
    }
    isVariantOf {
      productGroupID
      name
    }
    gtin
    additionalProperty {
      propertyID
      name
      value
      valueReference
    }
  }

  fragment Filter_facets on StoreFacetBoolean {
    key
    label
    values {
      label
      value
      selected
      quantity
    }
    __typename
  }
`

export const BrowserProductQuery = gql`
  query BrowserProductQuery($locator: [IStoreSelectedFacet!]!) {
    product(locator: $locator) {
      ...ProductDetailsFragment_product
    }
  }
`

export const ValidateCartMutation = gql`
  mutation ValidateCartMutation($cart: IStoreCart!, $session: IStoreSession!) {
    validateCart(cart: $cart, session: $session) {
      order {
        orderNumber
        acceptedOffer {
          ...CartItem
        }
      }
      messages {
        ...CartMessage
      }
    }
  }
`

export const ValidateSession = gql`
  mutation ValidateSession($session: IStoreSession!, $search: String!) {
    validateSession(session: $session, search: $search) {
      locale
      channel
      country
      postalCode
      currency {
        code
        symbol
      }
      person {
        id
        email
        givenName
        familyName
      }
    }
  }
`

export const ProductsQuery = gql`
  query ProductsQuery(
    $first: Int!
    $after: String
    $sort: StoreSort!
    $term: String!
    $selectedFacets: [IStoreSelectedFacet!]!
  ) {
    search(
      first: $first
      after: $after
      sort: $sort
      term: $term
      selectedFacets: $selectedFacets
    ) {
      products {
        pageInfo {
          totalCount
        }
        edges {
          node {
            ...ProductSummary_product
          }
        }
      }
    }
  }
`

export const ProductGalleryQuery = gql`
  query ProductGalleryQuery(
    $first: Int!
    $after: String!
    $sort: StoreSort!
    $term: String!
    $selectedFacets: [IStoreSelectedFacet!]!
  ) {
    search(
      first: $first
      after: $after
      sort: $sort
      term: $term
      selectedFacets: $selectedFacets
    ) {
      products {
        pageInfo {
          totalCount
        }
        edges {
          node {
            ...AdditionalProductInfos
          }
        }
      }
      facets {
        ...Filter_facets
      }
    }
  }
`
export default Page
