import React from 'react'

import useCmsFeatureToggle from '../../../hooks/useCmsFeatureToggle'

import './custom-theme.scss'

const TOGGLE_THEMES = ['hasDarkTheme']

export function CustomTheme() {
  const { featureToggle = {} } = useCmsFeatureToggle()

  const activeTheme = TOGGLE_THEMES.find((theme) => featureToggle[theme])

  if (!activeTheme) {
    return null
  }

  return <div className={activeTheme} style={{ display: 'none' }} />
}
